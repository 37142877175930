.Spinner {
  text-align: center;
}

.Spinner-image {
  display: block;
  max-width: 100px;
  height: auto;
  margin: 15px auto;
}

.Spinner-text {

}
