@media screen and (min-width: 1400px) {
  .CalendarCellCourse {
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    color: rgba(127, 127, 127, 0.7);
    line-height: 1.4;
    font-size: 12px;
    padding: 5px;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .CalendarCell:hover .CalendarCellCourse,
  .CalendarCell.isActive .CalendarCellCourse {
    background-color: #1251aa;
    border-color: #1251aa;
    color: #fff;
  }
}

@media screen and (max-width: 1400px) {
  .CalendarCellCourse {
    display: none;
  }
}
