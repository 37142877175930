.Icon {
  display: inline-block;
  position: relative;
  line-height: 0;
}

.Icon-count {
  position: absolute;
  top: -8px;
  right: -7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #d83f33;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?g1rpgn");
  src: url("../fonts/icomoon.eot?g1rpgn#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?g1rpgn") format("truetype"),
    url("../fonts/icomoon.woff?g1rpgn") format("woff"),
    url("../fonts/icomoon.svg?g1rpgn#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "icomoon-info";
  src: url("../fonts/icomoon-info.eot?g1rpgn");
  src: url("../fonts/icomoon-info.eot?g1rpgn#iefix") format("embedded-opentype"),
    url("../fonts/icomoon-info.ttf?g1rpgn") format("truetype"),
    url("../fonts/icomoon-info.woff?g1rpgn") format("woff"),
    url("../fonts/icomoon-info.svg?g1rpgn#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", "icomoon-info" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\ea0c";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-offer:before {
  content: "\e900";
}
.icon-home:before {
  content: "\e901";
}
.icon-calendar1:before {
  content: "\e902";
}
.icon-notes:before {
  content: "\e903";
}
.icon-file:before {
  content: "\e904";
}
.icon-slides:before {
  content: "\e905";
}
.icon-barcode:before {
  content: "\e906";
}
.icon-card:before {
  content: "\e907";
}
.icon-certified:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e909";
}
.icon-clock:before {
  content: "\e90a";
}
.icon-messages:before {
  content: "\e90b";
}
.icon-picture:before {
  content: "\e90c";
}
.icon-history:before {
  content: "\e90d";
}
.icon-users1:before {
  content: "\e90e";
}
.icon-ticket:before {
  content: "\e90f";
}
.icon-email:before {
  content: "\e910";
}
.icon-gallery:before {
  content: "\e911";
}
.icon-user:before {
  content: "\e912";
}
.icon-bell:before {
  content: "\e913";
}
.icon-cart:before {
  content: "\e914";
}
.icon-play:before {
  content: "\e915";
}
.icon-list:before {
  content: "\e916";
}
.icon-exit:before {
  content: "\e917";
}
.icon-close:before {
  content: "\e918";
}
.icon-support:before {
  content: "\e919";
}
.icon-text-background:before {
  content: "\e91a";
}
.icon-text-color:before {
  content: "\e91b";
}
.icon-underline:before {
  content: "\e91c";
}
.icon-italic:before {
  content: "\e91d";
}
.icon-bold:before {
  content: "\e91e";
}
.icon-telegram:before {
  content: "\e91f";
}
.icon-book:before {
  content: "\e920";
}
.icon-books:before {
  content: "\e921";
}
.icon-wallet:before {
  content: "\e922";
}
.icon-camera:before {
  content: "\e923";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-database:before {
  content: "\e964";
}
.icon-users:before {
  content: "\e972";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-android:before {
  content: "\eac0";
}
.icon-windows8:before {
  content: "\eac2";
}
.icon-star:before {
  content: "\f005";
}
.icon-cog:before {
  content: "\f013";
}
.icon-clock-o:before {
  content: "\f017";
}
.icon-download:before {
  content: "\f019";
}
.icon-volume-off:before {
  content: "\f026";
}
.icon-volume-down:before {
  content: "\f027";
}
.icon-volume-up:before {
  content: "\f028";
}
.icon-video-play:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-compress:before {
  content: "\f066";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-arrows-alt:before {
  content: "\f0b2";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-undo:before {
  content: "\e965";
}
.icon-redo:before {
  content: "\e966";
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome!important;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-qrcode:before {
  content: "\f029"!important;
  font-size: 34px;
  line-height: 34px;
}