.Sidebar {
  background-color: #ffffff;
  width: 300px;
  padding-top: 15px;
}

.Sidebar-user {
  padding: 20px;
  padding-top: 30px;
}

.Sidebar-menu {
  margin-top: 5px;
  padding: 0 10px;
}

.Sidebar-menu-item {
  height: 42px;
  position: relative;
}

.Sidebar-menu-item a {
  padding: 0 15px;
  transition: all 0.2s linear 0s;
}

.Sidebar-menu-item a .MenuButton-icon {
  margin-right: 15px;
}

.Sidebar .Sidebar-menu-item.onlyMobile {
  display: none;
}

.Sidebar-menu-item .newItem::before {
  position: absolute;
  content: 'novo';
  top: calc(50% - 8.5px);
  background-color: #FF6A58;
  color: #fff;
  font-size: 10px;
  padding: 2px 10px;
  border-radius: 3px;
  right: 5px;
}

/* Barra de Rolagem */

.Sidebar::-webkit-scrollbar { 
  width: 7px; 
  background:#e3e0d6; 
}

.Sidebar::-webkit-scrollbar-track { 
  background: rgba(0,0,0,0.1); 
}

.Sidebar::-webkit-scrollbar-thumb { 
  background:#8b8778; 
  cursor: pointer; 
}

/* Link Nova Plataforma ECJ */

.Sidebar .linkPlataformaECJ {
  width: 100%;
  height: 42px;
  position: relative;
  text-align: center;
  background: #FF6A58;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  transition: all 0.1s linear;
}

.Sidebar .linkPlataformaECJ:hover {
  transform: scale(1.02);
}

/* Desktop */
@media screen and (min-width: 1250px) {

  .Sidebar {
    width: 300px;
    min-height: calc(100vh - 68px);
    position: fixed;
    overflow: auto;
    top: 68px;
    bottom: 0;
    padding: 25px 5px;
  }

}

/* Mobile */
@media screen and (max-width: 1250px) {

  .Sidebar {
    display: none;
    width: 300px;
    position: fixed;
    top: 71px;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow-y: scroll;
  }

  .Sidebar.isActive {
    display: block;
  }

  .Sidebar .Sidebar-menu-item.onlyMobile {
    display: block;
  }

}