.DateSelect {
  display: flex;
  align-items: center;
}

.DateSelect-item {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
}

.DateSelect-controls-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 0;
  background-color: #fff;
  border: solid 1px #e5e5e5;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  margin-right: 3px;
  color: #5f5f5f;
  font-size: 18px;
}

.DateSelect-controls-button .Icon {
  position: relative;
  top: -1px;
}

.DateSelect-controls-button:hover {
  color: #1352aa;
}

/* Desktop */
@media screen and (min-width: 700px) {
  .DateSelect-controls {
    margin-right: 15px;
  }
}

/* Mobile */
@media screen and (max-width: 700px) {
  .DateSelect-controls {
    margin-left: 15px;
    order: 1;
  }

  .DateSelect {
    justify-content: flex-end;
  }
}
