.VideosTitle {
  display: flex;
  align-items: center;
}

.VideosTitle-icon {
  color: #1352aa;
  margin-right: 10px;
  line-height: 0;
  font-size: 22px;
}

.VideosTitle-text {
  margin: 0;
  color: #2b2b2b;
  font-size: 16px;
}

.VideosTitle-count {
  align-self: flex-start;
  font-size: 11px;
  color: #1352aa;
  font-weight: bold;
}
