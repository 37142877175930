.FormUser-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12.5px;
  margin-bottom: 20px;
}

.FormUser-grid-column {
  width: calc(50% - 25px);
  margin: 0 12.5px;
  margin-bottom: 25px;
}
  /* Mobile */
  @media screen and (max-width: 800px) {
    .FormUser-grid-column {
      width: 100%;
    }
  }

/* Large screen */
@media screen and (min-width: 500px) {
  .FormUser-grid-column.-two-fields {
    display: flex;
  }

  .FormUser-grid-column.-two-fields .FormUser-grid-column {
    margin-bottom: 0;
    margin: 0;
  }

  .FormUser-grid-column.-two-fields .FormUser-grid-column:first-child {
    width: 130px;
    flex-shrink: 0;
  }

  .FormUser-grid-column.-two-fields .FormUser-grid-column:last-child {
    flex-grow: 1;
    margin-left: 25px;
  }
}

/* Small screen */
@media screen and (max-width: 500px) {
  .FormUser-grid-column.-two-fields {
    margin-bottom: 0;
  }

  .FormUser-grid-column.-two-fields .FormUser-grid-column {
    margin-left: 0;
  }
}
