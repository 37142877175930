.FormGroupSelect {
  display: flex;
  align-items: center;
}

.FormGroupSelect-label {
  display: block;
  color: #7f7f7f;
  font-size: 14px;
  margin-right: 10px;
  flex-shrink: 0;
}

.FormGroupSelect-select {
  flex-grow: 1;
  width: 100%;
}
