.CalendarCell-wrapper {
  width: calc(1/7 * 100%);
}

.CalendarCell {
  width: 100%;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  color: #7f7f7f;
}
  @media screen and (min-width: 500px) {
    .CalendarCell {
      padding-top: 56%;
    }
  }
  @media screen and (max-width: 500px) {
    .CalendarCell {
      height: 40px;
    }
  }

.CalendarCell-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  padding: 10px;
  z-index: 100;
  border: 2px solid transparent;
}

.CalendarCell-day {
  display: block;
  font-size: 14px;
  text-align: right;
}

.CalendarCell-bottom {
  margin-top: auto;
}

@media screen and (max-width: 1400px) {
  .CalendarCell.hasConcourses .CalendarCell-bottom {
    display: none;
  }

  .CalendarCell-label {
    display: none;
  }

  .CalendarCell-day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: auto;
    flex-shrink: 0;
  }

  .CalendarCell.hasConcourses .CalendarCell-day {
    background-color: #1251aa;
    color: #fff;
    border-radius: 100%;
  }

  .CalendarCell-content {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .CalendarCell-day {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
}

.CalendarCell.isAdjacentMonth .CalendarCell-day {
  opacity: 0.3;
}

.CalendarCell-label {
  margin-top: 10px;
  font-size: 11px;
  text-align: center;
}

.CalendarCell-arrow {
  position: absolute;
  top: 100%;
  left: -1px;
  margin-top: 1px;
  width: calc(100% + 3px);
  height: 20px;
  background-color: #1251aa;
  color: #fff;
  text-align: center;
  border-radius: 0 0 3px 3px;
  display: none;
}

.CalendarCell-arrow .Icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transition: all 0.2s ease;
}

.CalendarCell.hasConcourses {
  cursor: pointer;
}

.CalendarCell.hasConcourses:hover,
.CalendarCell.isActive {
  z-index: 100;
  color: #1352aa;
}

.CalendarCell.hasConcourses:hover .CalendarCell-content,
.CalendarCell.isActive .CalendarCell-content {
  border: 2px solid #1352aa;
}

.CalendarCell.isActive,
.CalendarCell.hasConcourses:hover {
  z-index: 150;
}

.CalendarCell.hasConcourses:hover .CalendarCell-arrow,
.CalendarCell.isActive .CalendarCell-arrow {
  display: block;
}

.CalendarCell.isActive .CalendarCell-arrow .Icon {
  transform: translate(-50%, -50%) rotate(-180deg);
}
