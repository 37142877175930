.Player {
  color: rgba(255, 255, 255, 0.7);
  outline: none;
  float: left;
  width: 100%;
}

.Player-inner,
.Player-error {
  width: 100%;
  height: 100%;
}

.Player-error {
  background-color: #f1f1f1;
  color: #000;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
}