.PromoMaiorStoreBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.PromoMaiorStoreBoxColumn {
    flex-direction: column;
    width: 32%;    
    max-width: 319px;
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
}

.PromoMaiorStoreBoxWrap .PromoMaiorStoreBox,
.PromoMaiorStoreBoxWrap .PromoMaiorStoreBoxColumn {
    margin-bottom: 0;
}

@media (max-width: 700px) {
    .PromoMaiorStoreBoxColumn {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .PromoMaiorStoreItem .PromoMaiorStoreProduct button {
        padding: 0
    }

    .PromoMaiorStoreItem .PromoMaiorStoreProduct button .Icon {
        display: none;
    }
    .PromoMaiorStoreItem .PromoMaiorStoreProduct button .text {
        padding: 0 !important;
    }     
}

@media (min-width: 700px) {
    
    .PromoMaiorStoreBoxWrap {
        position: sticky;
        bottom: 0;
        width: 100%;
    }
}

.PromoMaiorStoreItem {
    flex-direction: column;
    background-color: #fff;
    min-height: 480px;
    display: flex;
    border: 2px solid #FFF;
}

.PromoMaiorStoreCheckoutItem {
    flex-direction: column;
    background-color: #fff;
    min-height: 200px;
    display: flex;
    border: 1px solid #D8D8D8;
    padding: 5px 25px;
    display: flex;
    justify-content: center;
}  
.PromoMaiorStoreCheckoutItem.summary {
    padding-left: 10px;
    border: 0;
    background-color: transparent;
    transform: scale(1.1);
}

.PromoMaiorStoreCheckoutDivider {
  flex-grow: 1;
  border-bottom: 1px solid #D8D8D8;
  margin: 5px
}

.PromoMaiorStoreCheckoutItem .type {
    color: #1717CE;
    font-weight: bold;
    font-size: 18px;
}

.PromoMaiorStoreCheckoutItem .name {
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

.PromoMaiorStoreCheckoutItem .link {
    color: #333;
    text-decoration: underline;
    padding-bottom: 15px;
}

.PromoMaiorStoreItem.is-active {
    border: 2px solid #FF6A58;
    border-bottom: 0;
}

.PromoMaiorStoreBoxColumn .removeButton {
    background-color: #666666;
    width: 100%;
    height: 56px;
    border: 2px solid #666666;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFF;
    font-size: 12px;
}

.PromoMaiorStoreBoxColumn .alert {
    background-color: #FF6A58;
    width: 100%;
    height: 30px;
    border: 2px solid #FF6A58;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: #FFF;
    align-items: center;
    display: flex;
    justify-content: center;
}

.PromoMaiorStoreItem .circle {
    width: 100px;
    height: 100px;
    background: #1D1DCF;
    border-radius: 50%;
    text-align: center;
    color: #FFF;
    word-break: break-word;
    font-size: 28px;
    font-weight: bold;
    padding: 15px;
    display: flex;
    align-items: center;
}

.PromoMaiorStoreItem .centered {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.PromoMaiorStoreItem .combo-name {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 25px;
}

.PromoMaiorStoreItem .combo-name .circle {
    margin: 0;
    min-width: 100px;
}

.PromoMaiorStoreItem .combo-name .plus {
    color: #FF6A58;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
}

.PromoMaiorStoreItem .combo-name .featured {
    color: #1D1DCF;
    max-width: 100px;
    font-size: 16px;
    font-weight: bold;
    word-wrap: break-word;
}

.PromoMaiorStoreItem .combo-name .featured span {
    color: #FF6A58;
}

.PromoMaiorStoreProduct {
    padding: 25px 50px;
}


.PromoMaiorStoreBox .from {
    color: #A9A9A9;
    padding-bottom: 5px;
    font-size: 18px;
}

.PromoMaiorStoreBox .to {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
}

.PromoMaiorStoreBox .button {
    padding-top: 10px;
}

.PromoMaiorStoreBox .button button {
    background-color: #FFFFFF;
    border: 2px solid #FF6A58;
    border-radius: 4px;
    height: 75px;
    width: 100%;
    text-align: left;
    color: #FF6A58;
    padding-left: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.PromoMaiorStoreBox .button button .text {
    padding-left: 45px;
}

.PromoMaiorStoreBox .button.is-active button {
    background-color: #FF6A58;
    color: #FFFFFF;
}

.PromoMaiorStoreBox .button.is-disabled button {
    color: #CCC;
    border: 2px solid #bbb;
    opacity: 0.5;
}

.PromoMaiorStoreCheckoutItem .saved_money {
    padding-top: 5px;
    font-size: 14px;
    font-weight: bold;
}

/***/

.PromoMaiorStoreBox .summary .type {
    font-size: 20px;
    padding-bottom: 5px;
}

.PromoMaiorStoreBox .summary .from {
    font-size: 12px;
}

.PromoMaiorStoreBox .summary .is-checkout button {
    background-color: #FF6A58;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
    display: flex;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
}
