.FormGroup {

}

.FormGroup.-right {
  text-align: right;
}

.FormGroup + .FormGroup {
  margin-top: 25px;
}
