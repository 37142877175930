.Alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: #fff;
  border-radius: 3px;
  margin: 15px 40px 0 40px;
}

.Alert.-default {
  background-color: #c1c1c1;
  color: #000;
}

.Alert.-success {
  background-color: #00a671;
}

.Alert.-error {
  background-color: #d83f33;
}

.Alert.-warning {
  background-color: #F9BF3B;
  color: #000;
}

.Alert-close {
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 20px;
  padding: 5px 10px;
  margin-left: 15px;
}

.Alert a:not(.Button) {
  color: #1352aa;
  text-decoration: underline;
}
