.CardToken {
  display: flex;
}

.CardToken + .CardToken {
  margin-top: 15px;
}

.CardToken-item {
  border-radius: 3px;
  background-color: #f1f1f1;
  padding: 10px 15px;
  border: none;
}

button.CardToken-item {
  color: #1352aa;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

button.CardToken-item[disabled] {
  opacity: 0.4;
}

button.CardToken-item:hover,
button.CardToken-item.isActive {
  background-color: #1352aa;
  color: #fff;
}

@media screen and (min-width: 600px) {
  .CardToken-item + .CardToken-item {
    margin-left: 10px;
  }

  button.CardToken-item {
    width: 90px;
  }
}

@media screen and (max-width: 600px) {
  .CardToken {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .CardToken-item {
    display: block;
    width: 100%;
  }

  .CardToken-item:not(:last-child):not(button) {
    margin-bottom: 5px;
  }

  button.CardToken-item {
    width: calc(50% - 3px);
  }
}
