.MenuButton {
  display: flex;
  align-items: center;
  width: 100%;
  height: inherit;
  padding: 0 20px;
  cursor: pointer;
  color: #333;
  background-color: transparent;
  border: none;
}

.MenuButton.-sidebar .MenuButton-icon {
  margin-right: 25px;
}

.MenuButton:hover,
.MenuButton.isActive {
  background-color: #F5F5F5;
  /* color: #1251aa; */
}

.MenuButton:focus {
  border: 0;
  outline: none;
}

.MenuButton-icon {
  margin-right: 15px;
  font-size: 22px;
  line-height: 0;
  color: #1717CE 
}

.MenuButton-grayscale img {
  filter: grayscale(1);
}

.MenuButton-text {
  font-size: 14px;
  font-weight: bold;
}

.MenuButton.-disable-hover {
  background-color: transparent;
}

@media screen and (max-width: 1250px) {
  .MenuButton.-only-desktop {
    display: none;
  }
}

@media screen and (min-width: 1250px) {
  .MenuButton.-only-mobile {
    display: none;
  }
}
