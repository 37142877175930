.campoBusca {
  display: block;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #D8D8D8;
  font-family: 'Roboto', sans-serif;
  color: #333333;
  font-size: 16px;
  outline: none;
  height: 50px;
  padding: 0 13px;
}

.containerCursos {
  float: left;
  width: 100%;
  margin: 30px 0;
}

.boxCurso {
  padding: 50px 30px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 5px 0 #0000000c;
  box-shadow: 0 2px 5px 0 #0000000c;
  border: solid 1px #e5e5e5;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  transition: all 0.2s ease;
}

.boxCurso:hover {
  background-color: #f5f5f5;
}

.boxCurso p {
  font-family: 'OpenSans', 'Poppins', 'Roboto', sans-serif;
  color: #373737;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  line-height: 150%;
}

.boxCurso img {
  position: absolute;
  right: 30px;
  top: calc(50% - 12px);
}

@media screen and (max-width: 768px) {

  .SectionTitle {
    line-height: 150%;
  }

  .boxCurso {
    padding: 60px 20px 30px 20px;
  }

  .boxCurso p {
    text-align: center;
    font-size: 16px;
  }

  .boxCurso img {
    right: 15px;
    top: 15px;
  }

}