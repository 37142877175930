.StyledScrollbars {
  overflow: auto;
}

.StyledScrollbars::-webkit-scrollbar {
  width: 9px;
}

/* Track */
.StyledScrollbars::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f1f1f1;
}

/* Handle */
.StyledScrollbars::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #5f5f5f;
}
