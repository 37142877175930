.Logo {
  display: block;
  max-width: calc(174px + 50px);
  position: relative;
  padding-right: 40px;
}

.Logo:hover {
  opacity: 0.7;
}

.Logo img {
  max-width: 100%;
  height: auto;
}

.Logo.-only-desktop {
  max-width: 100%;
  height: auto;
}

.Logo.-is-new {
  text-align: center;
  padding-left: 15px;
}

.Logo:not(.-is-new)::before {
  content: "BETA";
  background-color: #d83f33;
  position: absolute;
  color: #fff;
  font-size: 11px;
  padding: 3px;
  border-radius: 3px;
  top: 0;
  right: 0px;
}

@media only screen and (max-width: 1250px) {

  .Logo {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .Logo.-only-desktop {
    display: none;
  }

}

@media only screen and (min-width: 1251px) {
  .Logo.-only-mobile {
    display: none;
  }
}