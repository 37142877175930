.EcjSwitcher-container {
    padding: 0 10px;
    margin-bottom: 25px;
}

.EcjSwitcher {
    width: 100%;
    display: flex;
    justify-items: center;
    height: 48px;
    border: 1px solid #333;
    align-items: center;
    border-radius: 4px;
    margin-right: auto;
    margin-left: auto;
    font-weight: bold;
}

.EcjSwitcher-item {
    width: 50%;
    text-align: center;
    color: #333333;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.2s linear;
    font-size: 13px;
    border-left: solid 1px #333;
}

.EcjSwitcher-item:first-child {
    border: 0;
}

.EcjSwitcher-item:not(.is-active):hover {
    background-color: #F5F5F5;
}

.EcjSwitcher-item.is-active {
    color: #FFF;
    background-color: #1717CE;
}

.EcjSwitcher-item.is-active:before {
    content: "";
    position: absolute;
    top: 100%;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #1717CE;
    left: calc(50% - 8px);
}

.EcjSwitcher-item.is-new::after {
    position: absolute;
    content: 'novo';
    top: -10px;
    background-color: #FF6A58;
    color: #fff;
    font-size: 10px;
    padding: 2px 10px;
    border-radius: 3px;
    right: 5px;
}
