.MessageCollapse + .MessageCollapse {
  margin-top: 15px;
}

.MessageCollapse.-unread {
  border: solid 2px #1352aa;
}

.MessageCollapse.-unread .MessageCollapse-header-info-author,
.MessageCollapse.-unread .MessageCollapse-header-info-date {
  color: #1352aa;
}

.MessageCollapse-header {
  display: flex;
  padding: 10px 0;
  width: 100%;
  min-width: 0;
  padding-right: 30px;
}

.MessageCollapse-header-avatar {
  flex-shrink: 0;
  margin-right: 20px;
}

.MessageCollapse-header-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-width: 0;
}

.MessageCollapse-header-info-content {
  display: flex;
  align-items: center;
}

.MessageCollapse-header-info-content-author {
  font-family: 'Poppins', sans-serif;
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 600;
}

.MessageCollapse-header-info-content-date {
  font-size: 12px;
  color: #5f5f5f;
  margin: 0 5px;
}

.MessageCollapse-header-info-content-course {
  display: block;
  color: #2b2b2b;
  margin: 10px 0;
  margin-bottom: 5px;
  font-size: 15px;
}

.MessageCollapse-header-info-content-course:last-child {
  margin-bottom: 15px;
}

.MessageCollapse-body,
.MessageCollapse-header-info-resume {
  font-size: 15px;
  line-height: 200%;
  color: #5f5f5f;
  font-family: 'Roboto', sans-serif;
}

.MessageCollapse-body p {
  margin: 0;
  font-size: 14px;
  line-height: 200%;
  color: #5f5f5f;
  font-family: 'Roboto', sans-serif;
}

.MessageCollapse-body p + p {
  margin-top: 15px;
}

.MessageCollapse-header-info-resume {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  min-width: 0;
}

@media screen and (max-width: 700px) {
  .MessageCollapse-header-info-resume {
    display: none;
  }

  .MessageCollapse-header-info-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .MessageCollapse-header-info-content-date {
    margin: 5px 0;
  }
}
