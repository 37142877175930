.LessonCollapseHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.LessonCollapseHeader-title {

}

.LessonCollapseHeader-info {
  font-size: 14px;
}

.LessonCollapseHeader-info-check {
  color: #00a671
}

.LessonCollapseHeader-info-check-icon {
  display: inline-block;
  font-size: 18px;
  margin-right: 10px;
}
