.SectionTitle {
  font-family: 'OpenSans', 'Poppins', 'Roboto', sans-serif;
  color: #2b2b2b;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
}

.SectionTitle.is-secondary {
  font-size: 18px;
}

.SectionTitle.-no-margin {
  margin-bottom: 0;
}

/* Medium */
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .SectionTitle {
    font-size: 26px;
    margin-bottom: 25px;
  }

  .SectionTitle.is-secondary {
    font-size: 18px;
  }
}

/* Small */
@media screen and (max-width: 600px) {
  .SectionTitle {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .SectionTitle.is-secondary {
    font-size: 20px;
  }
}
