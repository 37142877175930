.PlayerControlOptions {
  width: 80px;
  background-color: rgba(43, 51, 63, 0.8);
}

.PlayerControlOptions-button {
  display: block;
  width: 100%;
  background-color: transparent;
  color: #fff;
  padding: 5px 10px;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  font-size: 13px;
  font-family: Roboto, sans-serif;
}

.PlayerControlOptions-button:hover {
  background-color: rgba(115, 133, 159, 0.5);
}

.PlayerControlOptions-button.isActive {
  background-color: #fff;
  color: #2B333F;
}