.IndiqueAmigo {
  padding: 20px;
}

.titleSub {
  color: #1717ce;
  font-size: 32px;
}

.description {
  font-size: 15px;
  color: #333;
  font-weight: bold;
  text-align: center;
  font-family: OpenSans;
}
.pontos {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}
.pontos-acumulados {
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  width: 250px;
  height: 50px;
  color: #333;
  font-weight: bold;
}

.Links {
  color: #ff6a58;
  font-weight: bold;
}

.selo-cliente {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selo-cliente-box {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  font-size: 20px;
  font-family: OpenSans;
}

.area-copiar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.input-copiar {
  background-color: #f5f5f5;
  border-radius: 8px 0 0 8px;
  height: 48px;
  padding: 15px;
  padding-right: 50px;
  color: #333333;
  border: 0px;
  width: 450px;
}

.btn-copiar {
  background-color: #ff6a58;
  border-radius: 0 8px 8px 0;
  width: 104px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-copiar span {
  color: #ffffff;
}

#box-desconto {
  max-width: 85%;
}

#box-pontos {
  margin-top: 50px;
  max-width: 85%;
}

.CardsList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.cardItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  margin-right: 30px;
}

.cardCircleItem {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cardItemText {
  max-width: 170px;
  margin-top: 30px;
}

@media screen and (min-width: 800px) {
  .IndiqueAmigo {
    display: flex;
  }

  .IndiqueAmigo-full-column {
    width: 100%;
  }

  .IndiqueAmigo-column {
    width: 50%;
  }

  .IndiqueAmigo-column + .IndiqueAmigo-column {
    margin-left: 40px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 800px) {
  .IndiqueAmigo-column + .IndiqueAmigo-column {
    margin-top: 40px;
  }

  #box-desconto {
    max-width: 100%;
  }

  .area-copiar {
    flex-direction: column;
  }

  .input-copiar {
    border-radius: 8px;
    width: 100%;
  }

  .btn-copiar {
    border-radius: 8px;
    width: 100%;
    margin-top: 10px;
  }

  #box-pontos {
    max-width: 100%;
  }
}

@media screen and (max-width: 1250px) {
  .pontos {
    margin-top: 50px;
  }
}
