.Table {
  width: 100%;
  border-collapse: collapse;
}

.Table td,
.Table th {
  border: 1px solid #e5e5e5;
  padding: 10px;
}

.Table th {
  background-color: #f1f1f1;
}

@media screen and (max-width: 800px) {
  .Table,
  .Table tbody,
  .Table tr,
  .Table td {
    display: block;
    padding: 0;
  }

  .Table thead {
    display: none;
  }

  .Table td::before {
    content: attr(data-column);
    width: 50%;
    flex-shrink: 0;
    background-color: #f1f1f1;
    font-weight: bold;
    padding: 10px;
  }

  .Table td {
    display: flex;
    align-items: center;
  }

  .Table td span {
    width: 50%;
    flex-shrink: 0;
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .Table,
  .Table a {
    font-size: 12px;
  }
}
