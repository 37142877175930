.Lesson-name {
  margin-bottom: 20px;
}

.Lesson-contentTop {
  margin-bottom: 20px;
}

.Lesson-content {
  margin: 0 0 20px 0;
  float: left;
  width: 100%;
}

/* Mobile */

@media screen and (max-width: 600px) {

  .Label-text {
    font-size: 16px;
  }

}

@media screen and (max-width: 600px) {

  .Label-text {
    font-size: 16px;
  }

}