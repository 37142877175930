.OrderCollapse:not(:last-child) {
  margin-bottom: 15px;
}

.OrderCollapse-header {
  padding: 10px 0;
  padding-right: 20px;
}

@media screen and (min-width: 600px) {
  .OrderCollapse-header {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 1250px) {
  .OrderCollapse-header-item {
    width: 25%;
  }
}

@media screen and (max-width: 1250px) and (min-width: 600px) {
  .OrderCollapse-header {
    flex-wrap: wrap;
  }

  .OrderCollapse-header-item {
    width: 50%;
  }

  .OrderCollapse-header-item:nth-child(3),
  .OrderCollapse-header-item:nth-child(4) {
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .OrderCollapse-header-item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.OrderCollapse-body {
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
}

.OrderCollapse-body-list {
  padding: 0;
  list-style-position: inside;
  font-size: 14px;
}

.OrderCollapse-body-list li {
  color: #1352aa;
  line-height: 24px;
}

.OrderCollapse-body-list li:not(:last-child) {
  margin-bottom: 10px;
}

.OrderCollapse-body-list li span {
  color: #5f5f5f;
  line-height: 24px;
}

.OrderCollapse-body-list li span strong {
  background: #1717CE;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.OrderCollapse-body-list li span a {
  color: #1717CE;
  text-decoration: underline;
}