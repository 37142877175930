.BlockInfo {
  display: flex;
  align-items: center;
}

.BlockInfo-icon {
  font-size: 30px;
  color: #a3a3a3;
  line-height: 0;
}

.BlockInfo-content {
  margin-left: 15px;
}

.BlockInfo-content-label {
  display: block;
  font-size: 12px;
  color: #5f5f5f;
  margin-bottom: 5px;
}

.BlockInfo-content-value {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #2b2b2b;
  font-weight: 600;
  line-height: 22px;
}
