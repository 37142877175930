.Subtitle {
  font-family: 'Poppins', 'Roboto', sans-serif;
  color:  #1251aa;
  font-size: 24px;
  font-weight: 600;
  padding: 0;
}

/* Medium */
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .Subtitle {
    font-size: 24px;
  }
}

/* Small */
@media screen and (max-width: 600px) {
  .Subtitle {
    font-size: 20px;
  }
}
