
.containerAulas .Box {
  margin-bottom: 15px;
  transition: all 0.2s ease;
}

.containerAulas a:last-child .Box {
  margin: 0;
}

.containerAulas .Box:hover {
  background-color: #f5f5f5;
}

.containerAulas .ItemBox span {
  font-family: 'OpenSans','Roboto',sans-serif;
  color: #5f5f5f;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.containerAulas .ItemBox p {
  font-family: 'OpenSans', 'Poppins', 'Roboto', sans-serif;
  color: #2b2b2b;
  font-size: 32px;
  font-weight: bold;
  margin: 10px 0 0 0;
  padding: 0;
}

@media screen and (min-width: 800px) {

}