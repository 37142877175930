.Link {
  font-family: 'Roboto', sans-serif;
  color: #1352aa;
  text-decoration: none;
  font-size: 16px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #1352aa;
  outline: none;
  cursor: pointer;
}

.Link:hover {
  color: #13528c;
  border-bottom-color: #13528c;
}

.Link.-small {
  font-size: 14px;
}

.Link.-large {
  font-size: 18px;
}
