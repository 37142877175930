.GridHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ContactHeader {
  margin-left: auto;
}

.ScreenHeader {
  margin: 0 0 30px 0;
}

.ScreenHeader h1 {
  color: #2b2b2b!important;
}

@media screen and (max-width: 768px) {
  .ScreenHeader {
    margin: 0 0 20px 0;
  }
}

@media screen and (min-width: 600px) {

  .ScreenHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

@media screen and (max-width: 600px) {

  .ScreenHeader-title {
    margin-bottom: 10px;
  }

  .ScreenHeader-title h1 {
    font-size: 24px;
  }

}
