.ListVideos {

}

@media screen and (min-width: 1300px) {
  .ListVideos-title {
    height: 50px;
  }

  .ListVideos-items {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    overflow: auto;
  }

  .ListVideos-items-video {
    padding-right: 20px;
  }

  .ListVideos-items-video:not(:last-child) {
    margin-bottom: 10px
  }
}

@media screen and (max-width: 1300px) {

  .ListVideos-title {
    margin-bottom: 15px;
  }

  .ListVideos-items {
    display: flex;
    position: relative;
    overflow: auto;
    padding-bottom: 10px;
  }

  .ListVideos-items-video {
    display: inline-block;
    width: auto;
    flex-shrink: 0;
    white-space: normal;
  }

  .ListVideos-items-video button {
    height: 100%;
  }

  .ListVideos-items-video:not(:last-child) {
    margin-right: 15px;
  }

}
