.FormCreditCard {

}

/* Desktop */
@media screen and (min-width: 600px) {
  .FormCreditCard-fields {
    display: flex;
    margin: 0 -5px;
  }

  .FormCreditCard-fields-column {
    width: calc(50% - 5px);
    margin: 0 5px;
  }
}

/* > Medium */
@media screen and (min-width: 450px) {
  .FormCreditCard-fields-column-expiry {
    display: flex;
    width: 100%;
    margin: 0 -5px;
  }

  .FormCreditCard-fields-column-expiry-item {
    width: calc(50% - 5px);
    margin: 0 5px;
  }
}

/* < Medium */
@media screen and (max-width: 450px) {
  .FormCreditCard-fields-column-expiry-item + .FormCreditCard-fields-column-expiry-item {
    margin-top: 20px;
  }
}

/* Mobile */
@media screen and (max-width: 600px) {
  .FormCreditCard-fields-column + .FormCreditCard-fields-column {
    margin-top: 20px;
  }
}
