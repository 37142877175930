.AgendaHeader {
  display: flex;
}

.AgendaHeader-date {

}

.AgendaHeader-filter {

}

@media screen and (min-width: 700px) {
  .AgendaHeader {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .AgendaHeader-date {
    width: 300px;
  }

  .AgendaHeader-filter {
    width: 400px;
  }
}

@media screen and (max-width: 700px) {
  .AgendaHeader {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .AgendaHeader-date {
    margin-top: 15px;
    order: 1;
  }

  .AgendaHeader-filter {

  }
}
