.StarRating {
  display: flex;
  align-items: center;
}

.StarRating-label {
  font-size: 14px;
  color: #000;
  margin-right: 7px;
}

.StarRating-stars {
  display: flex;
}

.StarRatingButton {
  background-color: transparent;
  border: none;
  color: #c1c1c1;
  font-size: 24px;
  padding: 0 3px;
  line-height: 0;
}

.StarRatingButton.isActive {
  color: #e79d35;
}
