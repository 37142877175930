.UserContent {
  line-height: 1.5;
}

.UserContent p {
  margin: 0 !important;
}

.UserContent p + p {
  margin-top: 15px !important;
}
