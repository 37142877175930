.ForumHeader {
  margin-top: 40px;
  margin-bottom: 20px;
}

@media screen and (min-width: 600px) {
  .ForumHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ForumHeader-filter {
    width: 300px;
  }
}

@media screen and (max-width: 600px) {
  .ForumHeader-button {
    margin-bottom: 20px;
  }
}
