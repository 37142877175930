.PlayerControl {
  height: inherit;
  position: relative;
  width: 33px;
}

.PlayerControl-button {
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 0;
  width: 33px;
  height: inherit;
  color: inherit;
  padding: 0;
  position: relative;
}

.PlayerControl-button > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PlayerControl .PlayerControl-button i {
  font-size: 18px;
  opacity: 0.95;
}

.PlayerControl:hover .PlayerControl-button i::before {
  text-shadow: 0em 0em 1em #fff, 0em 0em 0.5em #fff;
}

.PlayerControl:hover .PlayerControl-options {
  display: block;
}

.PlayerControl-options {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: auto;
  border-bottom: none;
  display: none;
}

@media screen and (max-width: 600px) {
  .PlayerControl-options {
    left: initial;
    right: -20px;
  }
}

@media screen and (max-width: 800px) {
  .PlayerControl.-only-desktop {
    display: none;
  }
}
