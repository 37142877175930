.painel-content {
  display: flex;
  align-items: center;
  flex-direction: column;  
}

  p {
    font-family: OpenSans;
  }

.box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title p {
  color: #1717CE;
  font-family: Avenir !important;
  font-size: 24px;
  line-height: 32.78px;
  text-align: left;  
  font-weight: 600;
}

.header-back a {
  margin-right: 33px;
  color: #666666;
  font-weight: bold;
  line-height: 21.79px;
}

.titleSub {
  font-family: OpenSans;
  color: #1717ce;
  font-size: 32px;
}

.numPontuacao {
  color: #1717ce;
  font-size: 48px;
}

.numPontuacaoDestak {
  color: #ff6a58;
  font-size: 48px;
  font-weight: 700;
}

.description {
  font-size: 15px;
  color: #333;
  font-weight: bold;
  text-align: center;
  font-family: OpenSans;
}

.Links {
  color: #ff6a58;
  font-weight: bold;
  font-size: 14px;
  font-family: OpenSans;
}

.CardsList {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.SquaredItem {
  width: 320px !important;
  margin-right: 30px;
}

#filters {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-historico p {
  color: #333333;
  font-family: Avenir;
  font-size: 24px;
  line-height: 32.78px;
  text-align: left;  
  font-weight: 700;
}

.search-pontuacao {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;
  color: #333333;
  font-weight: 700;
  font-size: 14px;
}

.search-pontuacao img {
  margin-right: 10px;
  cursor: pointer;
}

.box-historico {
  background: #FFFFFF;
}

@media screen and (min-width: 800px) {
  .PainelPontuacao {
    display: flex;
  }

  .full-column {
    width: 100%;
  }

  .column {
    width: 50%;
  }

  .column + .column {
    margin-left: 40px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 800px) {
  .column + .column {
    margin-top: 40px;
  }
}
