.VideoItem {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  padding: 12px;
  text-align: left;
  color: #7f7f7f;
}

.VideoItem-icon {
  font-size: 18px;
  margin-right: 15px;
  line-height: 0;
}

.VideoItem-info {
  display: block;
}

.VideoItem-info-index {
  display: block;
  font-size: 11px;
  margin-bottom: 3px;
  opacity: 0.9;
}

.VideoItem-info-title {
  display: block;
  font-size: 14px;
  line-height: 1.4;
}

/**
 * Hover.
 */

.VideoItem:hover {
  background-color: #f1f1f1;
}

/**
 * Watched.
 */

.VideoItem:not(.isSelected).-watched {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

.VideoItem:not(.isSelected).-watched .VideoItem-icon,
.VideoItem:not(.isSelected).-watched .VideoItem-info-index {
  color: #05a774;
}

.VideoItem:not(.isSelected).-watched .VideoItem-info-title {
  color: #000;
}

/**
 * Selected.
 */

.VideoItem.isSelected {
  background-color: #1352aa;
  border-color: #1352aa;
}

.VideoItem.isSelected .VideoItem-icon,
.VideoItem.isSelected .VideoItem-info {
  color: #fff;
}
