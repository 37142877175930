*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: #f1f1f1;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
  font-family: inherit;
}

/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

/* Box Conteudo */

#boxConteudo {
  scroll-behavior: smooth;
  display: inline-block;
  width: 100%;
}

/* Barra de Rolagem */

body::-webkit-scrollbar { 
  width:10px; 
  background:#e3e0d6; 
}

body::-webkit-scrollbar-track { 
  background: rgba(0,0,0,0.1); 
}

body::-webkit-scrollbar-thumb { 
  background:#8b8778; 
  cursor: pointer; 
}

/* Get Beemer */

#beamerSelector {
  width: 50px!important;
  height: 50px!important;
  top: 8px!important;
  opacity: 1!important;
  right: 30px!important;
}

@media only screen and (max-width: 1250px) {

  #beamerSelector {
    right: 10px!important;
  }

}

@media only screen and (max-width: 768px) {
  
  #boxConteudo {
    padding: 30px 15px!important;
  }

}
