.TeachersList {

}

.TeachersList-label {
  display: block;
  margin-bottom: 10px;
  color: #000;
  font-size: 14px;
}

.TeachersList-label-count {
  color: #1251aa;
  font-weight: bold;
}
