.Calendar {
}

.Calendar-weekdays {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.Calendar-weekdays-day {
  text-align: center;
  width: 100%;
}

.Calendar-grid {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  position: relative;
}

.Calendar-grid-row {
  display: flex;
}

.Calendar-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 500;
}
