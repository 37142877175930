.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EstrategiaCast {
  padding: 20px;
}

.titleCast {
  color: #333333;
  font-family: OpenSans;
  font-size: 32px;
  line-height: 43.71px;
  text-align: left;
}

.titleSub {
  font-family: OpenSans;
  font-weight: bold;
  color: #333333 !important;
}

.sub-titulo {
  color: #333333 !important;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 16px;
}

.distribuicao-cursos-description {
  color: #666666;
  font-family: OpenSans;
  font-size: 14px;
  line-height: 19.07px;
  width: 351px;
  text-align: left;
}

.descriptionCast {
  color: #333333;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 24px;
}

.preVantagens {
  color: #333333;
  font-family: OpenSans;
  font-size: 14px !important;
  line-height: 19.07px;
  text-align: left;
  font-weight: normal !important;
}

.CardsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.EstrategiaCastMobileApp {
  margin-top: -130px;
}

.EstrategiaCastApp {
  position: absolute;
  margin-top: -100px;
}

.AppOne {
  width: 298px;
  height: 547px;
  position: absolute;
}
.AppTwo {
  margin-left: 165px;
  margin-top: 125px;
}

.MobileApp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lnk-loja-download {
  margin-top: -30px;
}

.ItemDistribuicaoCurso {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 54px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  min-width: 300px;
  width: 49%;
  height: 450px;
  margin-bottom: 10px;
}

.BoxAproveite {
  min-height: 280px;
}

.ItemCast {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  width: 287px;
  height: 160px;
  margin-bottom: 32px;
  cursor: pointer;
}

.ItemCastSmall {
  width: 172px;
  height: 98px;
  font-weight: 700;
  color: #333333 !important;
  font-size: 16px;
}

.ItemCastSelected {
  color: #1717ce !important;
  border: 1px solid #1717ce !important;
}

.itemCastTile {
  font-family: OpenSans;
  color: #1717CE;
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  line-height: 32px;
  text-align: left;
  width: 320px;
}

.itemCastTitleDescription {
  color: #333333;
  font-family: OpenSans;
  font-size: 14px;
  line-height: 19.07px;
  width: 440px;
  text-align: left;
}

.EstrategiaCastLink {
  color: #FFFFFF;
  font-family: OpenSans;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .EstrategiaCast {
    display: flex;
  }

  .EstrategiaCast-full-column {
    width: 100%;
  }

  .EstrategiaCast-column {
    width: 50%;
  }

  .EstrategiaCast-column + .EstrategiaCast-column {
    margin-left: 40px;
    padding-left: 40px;
  }

  .EstrategiaCastMobileApp {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .EstrategiaCast-column + .EstrategiaCast-column {
    margin-top: 40px;
  }

  .ItemDistribuicaoCurso {
    width: auto;
    padding-top: 54px !important;
    margin-top: 32px;
  }

  .EstrategiaCastApp {
    display: none;
  }

  .lnk-loja-download {
    margin-top: 30px;
  }

  .titleCast {
    text-align: center;
  }
}
