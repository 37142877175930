.CircledItem {
    background-color: #FFFFFF;
    border-radius: 120px;
    width: 240px;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.CircledItem span {
    color: #333333;
    font-size: 16px;
    line-height: 20px;
    width: 160px;
    text-align: center;
    font-weight: bold;
}


.CircledItem .CustomIcon {
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
}