.CalendarCollapse-container {

}

.CalendarCollapse {
  background-color: #1352aa;
  color: #fff;
  padding: 20px;
  padding-top: 30px;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  position: relative;
}

.CalendarCollapse::after {
  content: " ";
  background-color: #1352aa;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
}

.CalendarCollapse-block {
  font-size: 14px;
}

.CalendarCollapse-block:not(:last-child) {
  margin-bottom: 30px;
}

.CalendarCollapse-block-title {
  display: block;
  color: #fff;
  font-weight: bold;
  margin: 0;
  margin-bottom: 15px;
}

.CalendarCollapse-block-item {
  display: block;
  color: #f1f1f1;
}

.CalendarCollapse-block-item:not(:last-child) {
  margin-bottom: 10px;
}
