.Label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.Label-text {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 18px;
}

.Label.isActive .Label-text {
  color: #1352aa;
}

.Label.small .Label-text {
  font-size: 14px;
}

/* Mobile */
@media screen and (max-width: 600px) {
  .Label-text {
    font-size: 16px;
  }
}
