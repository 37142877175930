.Tab {
  border: 0;
  border-bottom: 6px solid transparent;
  background-color: transparent;
  padding: 15px 30px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: none;
}

.Tab + .Tab {
  margin-left: 5px;
}

/* .Tab:not(.isActive):hover {
  background-color: #ebecec;
} */

.Tab.isActive {
  border-bottom: 6px solid #FF6A58;
  color: #FF6A58;
  font-weight: 600;
  position: relative;
  z-index: 150;
}

/* .Tab.isActive::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: -2px;
  margin-top: -1px;
  width: calc(100% + 4px);
  height: 5px;
  background-color: #fff;
}

.Tab:first-child.isActive::after {
  left: 0;
} */
