.Button {
  display: inline-block;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  background-color: #FF6A58;
  text-transform: uppercase;
  padding: 20px 40px;
  cursor: pointer;
  text-align: center;
}

.Button.-small {
  font-size: 14px;
  padding: 7px 10px;
  font-weight: 400;
}

.Button.-outlined {
  background-color: #FFFFFF;
  border: 2px solid #FF6A58;
  color: #FF6A58;
}

.Button.-outlined:not([disabled]):hover,
.Button.-outlined:not([disabled]):active {
  background-color: #fafafa;
}

.Button.-medium {
  font-size: 16px;
  padding: 15px;
  font-weight: 400;
}

.Button.-fit {
  max-width: fit-content;
  width: fit-content;
}

.Button:not([disabled]):hover,
.Button:not([disabled]):active {
  background-color: #ff3c25;
}

.Button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Medium */
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .Button {
    font-size: 16px;
    padding: 20px;
  }
}

/* Small */
@media screen and (max-width: 600px) {
  .Button {
    font-size: 16px;
    padding: 20px 5px;
    width: 100%;
  }
}

.Button.-danger {
  background-color: #d83f33;
}

.Button.-danger:not([disabled]):hover,
.Button.-danger:not([disabled]):active {
  background-color: #c0362b;
}

.Button.-special {
  font-size: 14px;
  text-transform: uppercase;
  background-color: #FF6A58;
}

.Button.-special:not([disabled]):hover,
.Button.-special:not([disabled]):active {
  background-color: #FF6A58;
}