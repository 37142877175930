.Box {
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 #0000000c;
  border: solid 1px #e5e5e5;
  display: inline-block;
  width: 100%;
}

.Box.isDisabled {
  opacity: 0.6;
}

.Box.isPadded {
  padding: 20px;
}

.isMargined {
  margin: 40px 20px;
}

.Box.isTransparent {
  background-color: transparent;
  box-shadow: none;
  border: 0;
}
