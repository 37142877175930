.LessonVideos .boxVisualizado {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  float: left;
  width: 100%;
}

@media screen and (min-width: 1300px) {

  .LessonVideos {
    display: flex;
  }

  .LessonVideos-player {
    flex-grow: 1;
  }

  .LessonVideos-playlist {
    width: 400px;
    flex-shrink: 1;
    margin-left: 40px;
    position: relative;
  }

}

@media screen and (max-width: 1300px) {

  .LessonVideos-playlist {
    margin: 25px 0;
    float: left;
    width: 100%;
  }

}
