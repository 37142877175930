.LessonButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.LessonButton[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
  user-select: none;
}

.LessonButton[disabled] .LessonButton-icon {
  color: #7f7f7f;
}

.LessonButton[disabled] .LessonButton-text {
  color: #7f7f7f;
}

@media screen and (min-width: 600px) {
  .LessonButton {
    height: 100px;
  }
}

@media screen and (max-width: 600px) {
  .LessonButton {
    padding: 10px;
  }
}

.LessonButton:not([disabled]):hover {
  background-color: #f1f1f1;
}

.LessonButton-inner {
  text-align: center;
}

.LessonButton-icon {
  display: block;
  font-size: 26px;
  color: #1352aa;
  margin-bottom: 3px;
  text-align: center;
}

.LessonButton-text {
  font-size: 14px;
  color: #2b2b2b;
  font-weight: bold;
}

.LessonButton > .baixado {
  position: absolute;
  left: 10px;
  top: 10px;
}

.LessonButton > .novo {
  position: absolute;
  top: 10px;
  background-color: #FF6A58;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px 2px 10px;
  border-radius: 3px;
  right: 10px;
  line-height: 100%;
}

.LessonButton-text span {
  color: #FF6A58;
  font-weight: bold;
}

.LessonButton-label {
  font-size: 11px;
  color: #7f7f7f;
}
