.CourseInfo-content {
  flex: 1;
}

.CourseInfo-content-title {
  font-size: 18px;
  font-weight: normal;
  margin: 5px 0;
  line-height: 1.4;
}

.CourseInfoRelative {
  position: relative;
}

@media screen and (min-width: 700px) {
  .CourseInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .CourseInfo-teachers {
    flex-shrink: 0;
    margin-left: 15px;
  }
}

@media screen and (max-width: 700px) {
  .CourseInfo-teachers {
    margin-top: 15px;
  }

  .CourseInfo-content-title {
    font-size: 16px;
  }
}
