.TeachersListSlider {
  width: 240px;
  position: relative;
  overflow: hidden;
}

.TeachersListSlider .slick-slider {
  padding: 0 25px;
  margin-top: 5px;
}

.TeachersListSlider .slick-slider .slick-slide {
  padding: 0 5px;
}

.TeachersListSlider .slick-slider .slick-slide img {
  width: 54px!important;
  height: 54px!important;
}

/* Setas */

.TeachersListSlider button::before {
  color: #2b2b2b;
}

.TeachersListSlider .slick-prev {
  left: 0;
}

.TeachersListSlider .slick-next {
  right: 0;
}