.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color:  transparent;
  width: 100%;
  position: fixed;
  height: 70px;
  top: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  border-bottom: solid 2px #F1F1F1;
  padding-right: 65px;
}

.Header-menu {
  display: flex;
  align-items: center;
  height: inherit;
  padding-right: 8px;
}

.Header-menu-item {
  margin: 0 8px;
}

.Header-lonely-icon {
  background-color: #FFF;
  height: 48px;
  width: 48px;
  margin: 24px 0;
}

.Header-lonely-icon.btnActive {
  background-color: #f5f5f5;
}

.Header-lonely-icon a.-only-desktop {
  padding: 0;
  justify-content: center;
}

.Header-lonely-icon a.-only-desktop .MenuButton-icon {
  margin: 0;
}

.Header-menu .Header-menu-details button {
  background: #fff;
  padding: 16px 10px 16px 20px!important;
}

.Header-Button-active {
  min-height: 48px;
  background: #F5F5F5!important;
}

.Header-menu-user {
  margin: 0 5px 0 0;
}

.Header-dropdown {
  right: -48px;
  position: absolute;
  min-height: 48px;
  z-index: 100;
  width: 100%;
  min-width: 250px;
  background: #fff;
  padding: 20px;
  -webkit-box-shadow: 0px 6px 18px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 6px 18px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 6px 18px -8px rgba(0,0,0,0.75);
}

.Gamification-details {
  padding: 8px 0;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
}

.Gamification-item {
  display: flex;
  justify-content: space-between;
}

.Gamification-item.-is-total {
  color: #FF6A58;
}

.Gamification-item.-is-total span {
  font-size: 16px;
}

.Header-dropdown-menu {
  padding-top: 20px;
}

.Header-dropdown-menu-item {
  padding: 0 !important;
  height: 24px !important;
  color: #333 !important;
  font-size: 14px;
  margin-bottom: 12px;
}

.Header-dropdown-menu-item:hover {
  background: transparent !important;
}

.Header-dropdown-menu-item:last-child {
  margin-bottom: 0;
}


@media (max-width: 1250px) {

  .Header {
    background: #FFFFFF;
    width: 100%;
  }

  .Header-menu {
    width: 100%;
    padding: 0;
    justify-content: flex-end;
  }

  .MenuButton.-only-desktop {
    display: flex!important;
  }

  .Header-menu .Header-menu-details button {
    display: none;
  }

  .MenuButton:hover, 
  .MenuButton.isActive {
    background: #fff!important;
  }

  .Header-dropdown {
    top: 25px;
  }
  
}

@media (max-width: 500px) {

  .Header-dropdown {
    right: -120px;
  }

}

@media (max-width: 380px) {

  .Header-dropdown {
    right: -225px;
  }

}