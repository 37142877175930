.Avatar {
  display: inline-block;
}

.Avatar-image {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 100%;
  border: 1px solid #f1f1e3;
}
