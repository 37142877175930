.Badge {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-size: 11px;
  border-radius: 3px;
  background-color:  #1352aa;
  padding: 6px 10px;
}

.Badge.-dark {
  background-color: #5f5f5f;
}
