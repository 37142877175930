.formRedacao .boxFormRedacao {
    display: inline-block;
    width: 100%;
    background: #F5F5F5;
    padding: 60px 20px;
    text-align: center;
    margin-bottom: 30px;
}

.formRedacao .boxFormRedacao p {
    font-size: 14px;
}

.formRedacao button {
    font-size: 14px;
    padding: 12px;
}