.LoadMore {
  text-align: center;
  margin-top: 30px;
}

@media screen and (max-width: 1000px) {
  .LoadMore {
    margin-bottom: 10px;
  }
}
