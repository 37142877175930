.CourseDiscursive {
  margin: 30px 0;
  padding: 25px;
  background: #ffffff;
  border: 2px solid #FF6A58;
}

.CourseDiscursive .headerBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Redações */

.CourseDiscursive .headerBox .redacoes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CourseDiscursive .headerBox .redacoes span {
  color: #333333;
  font-weight: bold;
  font-size: 20px;
}

/* Última Redação */

.CourseDiscursive .headerBox .conteudo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CourseDiscursive .headerBox .conteudo .ultimaEnviada {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CourseDiscursive .headerBox .conteudo .ultimaEnviada .item {
  padding: 0 30px;
}

.CourseDiscursive .headerBox .conteudo .ultimaEnviada span {
  color: #666666;
  margin-bottom: 7px;
  display: inherit;
}

/* Ícone Status - Última Redação */

.CourseDiscursive .headerBox .conteudo .ultimaEnviada .item.status strong {
  position: relative;
  padding-left: 15px;
}

.CourseDiscursive .headerBox .conteudo .ultimaEnviada .item.status strong::before {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  left: 0;
  border-radius: 50px;
  top: calc(50% - 3.5px);
}

.CourseDiscursive .headerBox .conteudo .ultimaEnviada .item.status.corrigido strong::before {
  background: #24B527;
}

.CourseDiscursive .headerBox .conteudo .ultimaEnviada .item.status.aguardando strong::before {
  background: #F9A82A;
}

/* Filtro */

.CourseDiscursive .filtro {
  position: relative;
}

.CourseDiscursive .filtro select {
  width: 250px;
  padding: 15px;
  border: 0.5px solid #D8D8D8;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;
}

.CourseDiscursive .filtro:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #FF6A58;
  position: absolute;
  right: 15px;
  top: calc(50% - 3px);
}

/* Botões */

.CourseDiscursive .headerBox .botoes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CourseDiscursive .headerBox .botoes button {
  font-size: 14px;
  padding: 20px;
  outline: none;
}

.CourseDiscursive .headerBox .botoes button:last-child {
  margin-left: 10px;
}

/* Listagem */

.CourseDiscursive .tableListagem {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
}

.CourseDiscursive .tableListagem table {
  width: 100%;
}

.CourseDiscursive .tableListagem table tr th {
  border-top: solid 1px #D8D8D8;
  border-bottom: solid 1px #D8D8D8;
  text-align: left;
  padding: 15px;
  line-height: 150%;
  font-size: 14px;
}

.CourseDiscursive .tableListagem table tr td {
  border-bottom: solid 1px #D8D8D8;
  text-align: left;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 24px;
}

.CourseDiscursive .tableListagem table tr td span {
  color: #333333;
  line-height: 150%;
  float: left;
}

.CourseDiscursive .tableListagem table tr td a {
  float: left;
  height: 24px;
  margin-left: 20px;
}

.CourseDiscursive .tableListagem table tr td audio {
  float: left;
}

/* Ícone Status */

.CourseDiscursive .tableListagem table tr td.status span {
  position: relative;
  padding-left: 15px;
}

.CourseDiscursive .tableListagem table tr td.status span::before {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  left: 0;
  border-radius: 50px;
  top: calc(50% - 3.5px);
}

.CourseDiscursive .tableListagem table tr td.status.corrigido span::before {
  background: #24B527;
}

.CourseDiscursive .tableListagem table tr td.status.aguardando span::before {
  background: #F9A82A;
}

@media only screen and (max-width: 900px) {
  
  .CourseDiscursive {
    padding: 20px;
  }

  /* Header */

  .CourseDiscursive .headerBox {
    display: inline-block;
  }

  .CourseDiscursive .headerBox .conteudo {
    display: inline-block;
    width: 100%;
    margin: 30px 0;
  }

  .CourseDiscursive .headerBox .conteudo .ultimaEnviada {
    display: inline-block;
  }

  .CourseDiscursive .headerBox .conteudo .ultimaEnviada .item {
    display: inline-block;
    width: 100%;
    padding: 10px 0;
  }

  .CourseDiscursive .headerBox .conteudo .ultimaEnviada .item span {
    width: 100%;
    display: inline-block;
  }

  .CourseDiscursive .filtro select {
    width: 100%;
  }

  .CourseDiscursive .headerBox .botoes {
    display: inline-block;
    width: 100%;
  }

  .CourseDiscursive .headerBox .botoes button {
    width: 100%;
    display: inline-block;
    margin: 0!important;
  }
  
  .CourseDiscursive .headerBox .botoes button:last-child {
    margin-top: 10px!important;
  }

  /* Listagem */

  .CourseDiscursive .tableListagem {
    overflow: auto;
    padding-bottom: 20px;
  }

  .CourseDiscursive .tableListagem table {
    min-width: 1400px;
  }

  .CourseDiscursive .tableListagem table tr th,
  .CourseDiscursive .tableListagem table tr td {
    padding: 15px 10px;
  }

  .CourseDiscursive .tableListagem table tr th br {
    display: none;
  }

}