.LessonButtonList {
  margin: 10px -5px 0 -5px;
}

@media screen and (min-width: 600px) {
  .LessonButtonList {
    display: flex;
  }

  .LessonButtonList > .LessonButton {
    margin: 0 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .LessonButtonList > .LessonButton:not(:last-child) {
    margin-bottom: 5px;
  }
}
