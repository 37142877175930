.CardItem + .CardItem {
  margin-top: 15px;
}

@media screen and (min-width: 800px) {
  .CardItem-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .CardItem-info {
    margin-right: 15px;
  }
}

@media screen and (max-width: 800px) {
  .CardItem-button {
    margin-top: 10px;
  }

  .CardItem-button .Button {
    width: 100%;
  }
}

.CardItem-info-label {
  display: block;
  color: #1352aa;
  font-size: 12px;
  margin-bottom: 10px;
}

.CardItem-info-title {
  color: #5f5f5f;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  line-height: 1.5;
}

.CardItem-button {
  flex-shrink: 0;
}
