.Coaching {
    padding: 20px;
  }

.CardsList {
    display: flex;
    justify-content: space-evenly;
    margin-top: 32px;
    flex-wrap: wrap;
}

.CoachingLink {
  color: #FF6A58;
  text-decoration:underline;
  font-weight: bold;
}

  
  @media screen and (min-width: 800px) {
    .Coaching {
      display: flex;
    }

    .Coaching-full-column {
        width: 100%;
      }
  
    .Coaching-column {
      width: 50%;
    }
  
    .Coaching-column + .Coaching-column {
      margin-left: 40px;
      padding-left: 40px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .Coaching-column + .Coaching-column {
      margin-top: 40px;
    }
  }
  