.Title {
  font-family: 'Poppins', 'Roboto', sans-serif;
  color:  #333333;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.is-primary {
  color:#1717CE !important;
}

.is-dark {
  color:#333333 !important;
}

.is-centered {
  text-align: center;
}
