.ForumMessage {

}

.ForumMessage:not(:last-child) {
  margin-bottom: 20px;
}

.ForumMessage-row {
  display: flex;
  padding: 20px;
}

.ForumMessage-row-avatar {
  flex-shrink: 0;
  width: 60px;
  margin-right: 15px;
}
  @media screen and (max-width: 600px) {
    .ForumMessage-row-avatar {
      display: none;
    }
  }

.ForumMessage-row-content {
  flex-grow: 1;
}

.ForumMessage-row.-answer {
  background-color: #f7f7f7;
  border-top: 1px solid #e5e5e5;
}

.ForumMessage-header {
  display: flex;
  margin-bottom: 15px;
}
  @media screen and (min-width: 600px) {
    .ForumMessage-header {
      align-items: center;
    }
  }
  @media screen and (max-width: 600px) {
    .ForumMessage-header {
      flex-direction: column;
      align-items: flex-start;
    }
  }

@media screen and (min-width: 600px) {
  .ForumMessage-header-avatar {
    display: none;
  }
}

.ForumMessage-header-author {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.ForumMessage-body {
  white-space: pre-wrap;
  font-family: Roboto, sans-serif;
}

.ForumMessage-header-date {
  font-size: 12px;
}
  @media screen and (min-width: 600px) {
    .ForumMessage-header-date {
      margin: 0 10px;
    }
  }
  @media screen and (max-width: 600px) {
    .ForumMessage-header-date {
      margin: 5px 0;
    }
  }
