.listPosts {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.listPosts .Box {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 35px 20px;
}

.listPosts .tituloPost {
    float: left;
    width: calc(100% - 120px);
}

.listPosts .titulo {
    font-family: 'OpenSans', 'Poppins', 'Roboto', sans-serif;
    color: #373737;
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px 0 0;
    padding: 0;
    line-height: 150%;
}

.listPosts .data {
    color: #999;
    font-size: 13px;
    margin: 0;
    padding: 0;
    line-height: 150%;
}

.listPosts a {
    position: absolute;
    top: calc(50% - 24px);
    right: 20px;
}

@media only screen and (max-width: 768px) {

    .listPosts .Box {
        display: inline-block;
        width: 100%;
        position: relative;
    }

    .listPosts .tituloPost {
        width: 100%;
        margin: 0;
    }

    .listPosts a {
        position: relative;
        right: auto;
        margin-top: 15px;
        float: right;
    }

}