.SquaredItem {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  width: 160px;
  font-weight: bold;
  width: 287px;
  height: 160px;
  margin-bottom: 32px;
}

.SquaredItem.-small {
  width: 160px;
  height: 40px;
}
