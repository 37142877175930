.Collapse-header-container {
  display: flex;
  align-items: center;
}

.Collapse-header {
  display: block;
  cursor: pointer;
  padding: 10px 20px;
  user-select: none;
  outline: none;
  position: relative;
  flex-grow: 1;
  min-width: 0;
}

.Collapse-header-side {
  margin-right: 20px;
}

.Collapse-body {
  padding: 20px;
  padding-top: 0;
}

.Collapse.isDisabled .Collapse-header {
  cursor: not-allowed;
}

.Collapse-header-arrow {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  line-height: 0;
  color: #1352aa;
  transition: all 0.2s ease;
}

.Collapse-header-arrow.isOpened {
  transform: translateY(-50%) rotate(180deg);
}
