.InviteFriend {
  padding: 20px;
}

@media screen and (min-width: 800px) {
  .InviteFriend {
    display: flex;
  }

  .InviteFriend-column {
    width: 50%;
  }

  .InviteFriend-column + .InviteFriend-column {
    border-left: 1px solid #e5e5e5;
    margin-left: 40px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 800px) {
  .InviteFriend-column + .InviteFriend-column {
    margin-top: 40px;
  }
}
