
.banner-slider-thumbs {
    display: inline-block;
    width: 100%;
    margin-bottom: 120px;
}

.banner-slider-thumbs .banner-slider-thumbs-item {
    outline: none;
    border: 0;
    width: 100%;
    background: none;
    border: none;
}

.banner-slider-thumbs .banner-img {
    border-radius: 20px;
    width: 100%;
}


.banner-slider-thumbs .slick-list {
    padding: 0px 50px 0 0!important;
}

.banner-slider-thumbs .slick-list::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 4px;
    right: -2px;
    width: 50px;
    background-image: linear-gradient(to left, #F1F1F1, transparent);
    z-index: 999;
}

/* Paginação */

.banner-slider-thumbs .slick-dots {
    bottom: -30px;
    text-align: right;
    padding-right: 10px;
}

.banner-slider-thumbs .slick-dots li {
    margin: 0;
}

.banner-slider-thumbs .slick-dots li button {
    padding: 0;
}

.banner-slider-thumbs .slick-dots li button:before {
    font-size: 14px;
    color: #1717CE;
}

.banner-slider-thumbs .slick-dots li.slick-active button:before {
    color: #1717CE;
}

@media (max-width: 800px) {
  
    .banner-slider-thumbs {
        margin-bottom: 60px;
    }

}