.PromoMaior {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PromoMaior-column {
  flex-direction: column;
}

.PromoMaior-featured-img {
  max-height: 269px;
  transform: scale(1.4);
}

.PromoMaior-systems {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
} 

.PromoMaior-system {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFFFF;
  border: 2px solid #979797;
  border-radius: 4px;
  width: 136px;
  height: 136px;
  margin: 15px;
  padding: 10px;
}

@media (max-width: 700px) {
  .PromoMaior-system {
    width: 100%;
    height: 136px;
    margin: 15px;
  }
}

.PromoMaior-checkbox {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}

.PromoMaior-system button {
  width:  30px;
  height: 30px;
  border-radius: 50%;
  background: 0#FFF;
  border: 2px solid #666;
  margin-left: auto;
}

.PromoMaior-system span {
  color: #666666;
  font-weight: bold;
  text-align: center;
}

.PromoMaior-Input {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  background-color: #F5F5F5;
  border: 2px solid #FF6A58;
  border-radius: 8px;
  max-width: 665px;
}

.PromoMaior-uploadMessage {
  color: #333333;
  font-size: 22px;
  font-weight: 800;
  line-height: 22px;
}

.PromoMaior-system .Icon {
  visibility: hidden;
}

.PromoMaior-system.is-active {
  border: 2px solid #FF6A58;
}

.PromoMaior-system.is-active button {
  border: 2px solid #FF6A58 !important;
}

.PromoMaior-system.is-active span {
  color: #FF6A58;
}

.PromoMaior-system.is-active .Icon {
  visibility: visible;
  color: #FF6A58;
}

.PromoMaior {
  display: flex;
  align-items: center;
}

.-sidePadding {
  padding: 20px 0 !important;
}

.-headPadding {
  padding: 0 20px !important;
  background-color: #121520;
}

@media screen and (min-width: 800px) {

  .PromoMaior-column {
    width: 50%;
  }

  .PromoMaior-column + .PromoMaior-column {
    margin-left: 40px;
    padding-left: 40px;
  }

  .PromoMaior-column .-is-right {
    margin-left: auto;
    margin-right: 0;
  }
  
  .PromoMaior-column.-is-full {
    width: 100%;
  }
  
}

@media screen and (max-width: 800px) {

  .PromoMaior {
    flex-wrap: wrap;
    flex-direction: row;
  }  

  .PromoMaior-column {
    width: 100%;
  }

  .PromoMaior-column + .PromoMaior-column {
    margin-top: 40px;
  }

  .PromoMaior-featured-img {
    max-height: 120px;
    margin-left: auto;
    margin-right: auto;
  }
}
