.Input {
  display: block;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #D8D8D8;
  font-family: 'Roboto', sans-serif;
  color:  #333333;
  font-size: 16px;
  outline: none;
}

.Input:not(textarea) {
  height: 50px;
  padding: 0 13px;
}

textarea.Input {
  padding: 13px;
}

.Input[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #fff;
  user-select: none;
}

.Input:focus {
  border: solid 1px #1352aa;
}
