.TabPanels {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 100;
  border: 1px solid #e5e5e5;
  padding: 30px;
}

@media only screen and (max-width: 768px) {
  
  .TabPanels {
    padding: 20px;
  }

}